import React from "react"
import styled from "styled-components"

const StyledTextField = styled.input.attrs(props => ({
  type: "text",
  ...props
}))`
  padding: 8px;
  width: 100%;
  border: 1px solid #d2d2d2;
`

const TextField = props => {
  return <StyledTextField type="text" {...props} />
}

export default TextField
