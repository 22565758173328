import styled from "styled-components"

import Box from "./box"

const RatioContainer = styled(Box).attrs(props => ({
  pb: {
    _: props.heights && props.heights.mobile,
    desktop: props.heights && props.heights.desktop,
    tablet: props.heights && props.heights.tablet
  },
  ...props
}))`
  position: relative;
  &:after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
  }

  & > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export default RatioContainer
