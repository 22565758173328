import React from "react"
import styled, { css, keyframes } from "styled-components"

import Box from "./box"

/**
 * Shimmer loading indicator
 * @example
 * <Shimmer width="100px" height="20px" />
 */

const placeholderShimmer = keyframes`
  0% {
    background-position: -256px 0;
  }
  100% {
    background-position: 256px 0;
  }
`

const Shimmer = styled(Box)`
  background: ${props =>
    props.theme.colors ? props.theme.colors.grey[10] : "#ccc"};
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 100px;
  animation: ${placeholderShimmer} 1.5s forwards infinite;
  overflow: hidden;
`

export default Shimmer
