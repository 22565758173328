import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  ${props => {
    let size = 16
    switch (props.size) {
      case "sm":
        size = 8;
      case "md":
        size = 24;
        break;
      case "lg":
        size = 36;
        break
    }
    return `
      width: ${size}px;
      height: ${size}px;
    `
  }}
  display: inline-block;
  background: white;
  border-radius: 2px;
  transition: all 150ms;
  border: 1px solid black;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`

const Checkbox = ({ className, checked, size, ...props }) => (
  <CheckboxContainer className={className} onClick={e => {
    console.log("Click container")
  }}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} size={size} onClick={e => props.onChange(e)}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox
