import styled from "styled-components"
import {
  border,
  compose,
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
} from "styled-system"

/**
 * Typography
 * @prop {string=h1', 'h2', 'h3', 'body1', 'body2', 'body3'} variant - typography variants
 * @prop {boolean} bold - sets font-weight to bold
 * @prop {boolean} light - sets font-weight to light
 * @prop {boolean} italic - sets font-style to italic
 */
// TODO: Add variants such as h1, h2...
const Typography = styled.p`
  font-size: ${(props) => props.theme.fontSizes[12]};
  margin: 0;

  ${(props) =>
    props.gutterbottom &&
    `
    margin-bottom: 10px;
  `}
  ${(props) =>
    props.bold &&
    `
    font-weight: ${props.theme.fontWeights.bold};
  `}
  ${(props) =>
    props.light &&
    `
    font-weight: ${props.theme.fontWeights.light};
  `}
  ${(props) =>
    props.italic &&
    `
    font-style: italic;
  `}
  ${(props) =>
    props.underline &&
    `
    text-decoration: underline;
  `}
  ${(props) =>
    props.uppercase &&
    `
    text-transform: uppercase;
  `}
  ${(props) =>
    props.variant === "h1" &&
    `
    font-size: 2.25em;
    font-weight: 700;
  `}
  ${(props) =>
    props.variant === "h2" &&
    `
    font-weight: 700;
    font-size: 1.5em;
  `}
  ${(props) =>
    props.variant === "h3" &&
    `
    font-weight: 700;
    font-size: 1.25em;
  `}
  ${(props) =>
    props.variant === "body1" &&
    `
    font-size: ${props.theme.fontSizes[12]};
    ${
      props.theme.mediaQueries.tablet &&
      `
      font-size: 1.5em;
    `
    }
  `}
  ${(props) =>
    props.variant === "body2" &&
    `
    font-size: ${props.theme.fontSizes[14]};
    ${props.theme.mediaQueries.tablet`
      font-size: 1.25em;
    `}
  `}
  ${(props) =>
    props.variant === "body3" &&
    `
    font-size: ${props.theme.fontSizes[16]};
  `}
  ${(props) =>
    props.pointer &&
    `
    cursor: pointer;
  `}
  ${compose(border, color, flexbox, layout, position, space, typography)}
`

export default Typography
