import React from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  layout,
  position,
  space,
  typography,
  border
} from "styled-system"

const StyledButton = styled.button.attrs(props => ({
  type: "button",
  ...props
}))`
  padding: 8px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  line-height: ${props => {
    switch (props.size) {
      case "sm":
        return "10px"
      case "md":
        return "20px"
      case "lg":
        return "30px"
    }
    return "16px"
  }};

  ${props =>
    (props.variant === "primary" || props.primary) &&
    `
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.primary};
  `};

  ${props =>
    props.outline &&
    `
      color: ${props.theme.colors[props.outline]};
      border: 1px solid ${props.theme.colors[props.outline]};
    `};

  ${compose(border, color, layout, position, space, typography)}

  &:active {
    box-shadow: 0 0 10px inset #52505e;
  }

  &:disabled {
    background-color: #ccc;
    border-color: transparent;
  }
`

const Button = props => {
  return <StyledButton {...props}>{props.children}</StyledButton>
}

export default Button
