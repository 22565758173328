import { FontAwesomeIcon as BaseFontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import styled, { css, keyframes } from "styled-components"
import { color, compose, layout, typography, space } from "styled-system"

const faSpin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const WrappedFontAwesomeIcon = ({
  pointer, // eslint-disable-line no-unused-vars
  ...props
}) => <BaseFontAwesomeIcon {...props} />

WrappedFontAwesomeIcon.propTypes = {
  pointer: PropTypes.bool
}

const FontAwesomeIcon = styled(WrappedFontAwesomeIcon)`
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;

  &.svg-inline--fa.fa-w-1 {
    width: 0.0625em; }
  &.svg-inline--fa.fa-w-2 {
    width: 0.125em; }
  &.svg-inline--fa.fa-w-3 {
    width: 0.1875em; }
  &.svg-inline--fa.fa-w-4 {
    width: 0.25em; }
  &.svg-inline--fa.fa-w-5 {
    width: 0.3125em; }
  &.svg-inline--fa.fa-w-6 {
    width: 0.375em; }
  &.svg-inline--fa.fa-w-7 {
    width: 0.4375em; }
  &.svg-inline--fa.fa-w-8 {
    width: 0.5em; }
  &.svg-inline--fa.fa-w-9 {
    width: 0.5625em; }
  &.svg-inline--fa.fa-w-10 {
    width: 0.625em; }
  &.svg-inline--fa.fa-w-11 {
    width: 0.6875em; }
  &.svg-inline--fa.fa-w-12 {
    width: 0.75em; }
  &.svg-inline--fa.fa-w-13 {
    width: 0.8125em; }
  &.svg-inline--fa.fa-w-14 {
    width: 0.875em; }
  &.svg-inline--fa.fa-w-15 {
    width: 0.9375em; }
  &.svg-inline--fa.fa-w-16 {
    width: 1em; }
  &.svg-inline--fa.fa-w-17 {
    width: 1.0625em; }
  &.svg-inline--fa.fa-w-18 {
    width: 1.125em; }
  &.svg-inline--fa.fa-w-19 {
    width: 1.1875em; }
  &.svg-inline--fa.fa-w-20 {
    width: 1.25em; }

  ${props =>
    props.border
      ? `
    border: solid 0.08em #eee;
    border-radius: .1em;
    height: 1.5em;
    padding: .2em .25em .15em;
  `
      : ""}
  ${props =>
    props.fixedWidth
      ? `
    text-align: center;
    width: 1.25em;
  `
      : ""}
  ${props =>
    props.flip === "both"
      ? `
    transform: scale(-1, -1);
  `
      : ""}
  ${props =>
    props.flip === "horizontal"
      ? `
    transform: scale(-1, 1);
  `
      : ""}
  ${props =>
    props.flip === "vertical"
      ? `
    transform: scale(1, -1);
  `
      : ""}
  ${props =>
    props.inverse
      ? `
    color: #fff;
  `
      : ""}
  ${props =>
    props.listItem
      ? `
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit;
  `
      : ""}
  ${props =>
    props.pull === "left"
      ? `
    float: left;
    margin-right: .3em;
  `
      : ""}
  ${props =>
    props.pull === "right"
      ? `
    float: right;
    margin-left: .3em;
  `
      : ""}
  ${props =>
    props.pulse
      ? css`
          animation: ${faSpin} 1s infinite steps(8);
        `
      : ""}
  ${props =>
    props.rotate
      ? `
    filter: none;
    rotate(${props.rotate}deg);
  `
      : ""}
  ${props =>
    props.size && props.size[1] == "x"
      ? `font-size: ${props.size.replace("x", "")}em;`
      : ""}
  ${props => (props.size == "xs" ? "font-size: .75em;" : "")}
  ${props => (props.size == "sm" ? "font-size: .875em;" : "")}
  ${props =>
    props.size == "lg"
      ? `
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.225em;
  `
      : ""}
  ${props =>
    props.spin
      ? css`
          animation: ${faSpin} 2s infinite linear;
        `
      : ""}
  ${props =>
    props.pointer &&
    `
    cursor: pointer;
  `}
  ${compose(color, layout, typography, space)}
`

export default FontAwesomeIcon
