import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Box from "components/common/box"

/** Image element
 * @param {bool} center - centers image
 * @example
 * <Image responsiveWidth={{ mobile: "100%", tablet: "200px"}} display="block" src={...} />
 */
const StyledImage = styled(Box).attrs((props) => ({
  as: "img",
  height: "auto",
  maxWidth: "100%",
  ...props,
}))`
  ${(props) =>
    props.center &&
    `
    display: block;
    margin-left: auto;
    margin-right: auto;
  `};
`

const Image = ({ lazyload = true, src, srcSet, ...props }) =>
  lazyload ? (
    <StyledImage
      className="lazyload"
      data-src={src}
      data-srcset={srcSet}
      src={require("images/icons/jewlr.gif")}
      {...props}
    />
  ) : (
    <StyledImage src={src} srcset={srcSet} {...props} />
  )

Image.propTypes = {
  lazyload: PropTypes.bool,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  style: PropTypes.object,
}

export default Image
