import React from "react"
import { Link as RouterLink } from "react-router-dom"
import styled from "styled-components"

import Typography from "./typography"

/**
 * Link component
 * @example
 * <Link to="/">click here</Link>
 */
const Link = styled(Typography).attrs(props => ({
  as: RouterLink,
  color: "link",
  ...props
}))`
  font-size: inherit;
`
export default Link
