import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { display } from "styled-system"

import Box from "./box"

/**
 * Flex container, shares all props from Box
 * @prop {integer|string} gutter - adds grid gutters
 * @prop {boolean} addYPadding - adds vertical gutter to child components
 *
 * @example Grid with gutter of mobile: 8px, tablet: 16px
<Flex gutter={{ _: 1, tablet: 2}}>
  <Box />
  <Box />
</Flex>
 */

const StyledFlex = styled(Box)`
  display: flex;
  ${display};
`
/*eslint-disable react/display-name */
const Flex = React.forwardRef((props, ref) => {
  const { addYPadding, children, gutter } = props

  if (gutter) {
    let containerGutter = typeof gutter === "string" ? `-${gutter}` : -gutter
    if (typeof gutter == "object") {
      containerGutter = {}
      Object.keys(gutter).map(
        (breakpoint) =>
          (containerGutter[breakpoint] =
            typeof gutter[breakpoint] === "string"
              ? `-${gutter[breakpoint]}`
              : -gutter[breakpoint])
      )
    }
    let padding
    if (addYPadding) {
      padding = { p: gutter }
    } else {
      padding = { px: gutter }
    }
    return (
      <StyledFlex
        ml={containerGutter}
        mr={containerGutter}
        ref={ref}
        {...props}
      >
        {React.Children.map(children, (child) => {
          if (children && child) {
            // check if the element is valid before cloning
            return React.cloneElement(child, padding)
          }
        })}
      </StyledFlex>
    )
  } else {
    return (
      <StyledFlex ref={ref} {...props}>
        {children}
      </StyledFlex>
    )
  }
})

Flex.propTypes = {
  addYPadding: PropTypes.bool,
  children: PropTypes.node,
  gutter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
}

export default Flex
