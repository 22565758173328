import styled from "styled-components"
import { compose, color, layout, space } from "styled-system"

const Divider = styled.hr`
  border: none;
  height: 1px;
  outline: none;
  background-color: ${props =>
    props.theme.colors[props.color] ||
    props.color ||
    props.theme.colors.grey20};
  ${compose(color, layout, space)};
`

export default Divider
