import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Box from "components/common/box"

const StyledImage = styled(Box).attrs((props) => ({
  as: "img",
  height: "auto",
  maxWidth: "100%",
  ...props,
}))``

const LazyImage = ({ className, fallback, src, srcSet, ...props }) => {
  const [bustCounter, setBustCounter] = useState(0)
  useEffect(() => {
    setBustCounter(bustCounter + 1)
  }, [src, srcSet])

  return (
    <StyledImage
      className={`${className} lazyload ll-bust-${bustCounter}`}
      data-src={src}
      data-srcset={srcSet}
      src={fallback || require("images/icons/jewlr.gif")}
      {...props}
    />
  )
}

LazyImage.propTypes = {
  className: PropTypes.string,
  fallback: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.string,
}

export default LazyImage
