import styled, { css } from "styled-components"

/**
 * Divider with centered text
 * @props {string} mv - margin top and bottom
 * @props {string} mt - margin top
 * @props {string} mb - margin bottom
 * @props {string} bgColor - text background color
 * @props {string} borderColor - border color
 * @props {string} fontColor - font color
 *
 * @example
 *  <DividerText>
 *    <span>Some text</span>
 *  </DividerText
 */

const DividerText = styled.div`
  border-bottom: 1px solid
    ${(props) =>
      props.theme.colors[props.borderColor] ||
      props.borderColor ||
      props.theme.colors.grey20};
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 0;
  margin: ${(props) => props.mv || props.mt || "20px"} 0
    ${(props) => props.mv || props.mb || "20px"};
  text-align: center;
  width: 100%;
  span {
    background-color: ${(props) =>
      props.theme.colors[props.bgColor] ||
      props.bgColor ||
      props.theme.colors.white};
    padding: 0 10px;
  }
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `};
  ${(props) =>
    props.fontColor &&
    css`
      color: ${props.fontColor};
    `};
`
export default DividerText
