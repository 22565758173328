import PropTypes from "prop-types"
import React from "react"

import Box from "components/common/box"

export const Picture = ({ fallback, lazyload = true, srcSet, style }) => (
  <picture>
    {srcSet.map((src, index) =>
      lazyload ? (
        <source
          data-srcset={src.srcSet}
          key={`src${index}`}
          media={src.media}
          sizes={src.sizes}
        />
      ) : (
        <source
          key={`src${index}`}
          media={src.media}
          sizes={src.sizes}
          srcSet={src.srcSet}
        />
      )
    )}
    {lazyload ? (
      <Box
        as="img"
        className="lazyload"
        data-src={fallback}
        src={require("images/icons/jewlr.gif")}
        width="100%"
        {...style}
      />
    ) : (
      <Box as="img" src={fallback} width="100%" {...style} />
    )}
  </picture>
)

Picture.propTypes = {
  fallback: PropTypes.string,
  lazyload: PropTypes.bool,
  srcSet: PropTypes.array,
  style: PropTypes.object,
}

export default Picture
