import styled from "styled-components"
import {
  background,
  border,
  color,
  compose,
  flexbox,
  layout,
  space,
  position,
  typography,
} from "styled-system"

/**
 * Component used as grid column, or generic div
 * @prop {bool} pointer - sets cursor to pointer
 * @prop {bool} contain - adds max-width to contain the box
 */
const Box = styled.div`
  ${compose(
    background,
    border,
    color,
    flexbox,
    layout,
    space,
    position,
    typography
  )}
  ${(props) =>
    props.pointer &&
    `
    cursor: pointer;
  `}
  ${({ theme, ...props }) =>
    props.containWide &&
    `
      margin-left: auto;
      margin-right: auto;
      ${theme.mediaQueries.tablet`
        padding-left: 16px;
        padding-right: 16px;
      `}
      ${theme.mediaQueries.desktop`
        max-width: 90em;
      `}
    `}
  ${({ theme, ...props }) =>
    props.contain &&
    `
    margin-left: auto;
    margin-right: auto;
    ${theme.mediaQueries.tablet`
      ${
        !props.px &&
        `
          padding-left: 16px;
          padding-right: 16px;
      `
      }
      max-width: ${theme.container.tablet};
    `};
    ${theme.mediaQueries.desktop`
      max-width: ${theme.container.desktop};
    `};
    ${theme.mediaQueries.widescreen`
      max-width: ${theme.container.widescreen};
    `};
  `}
`
export default Box
